import React, { useEffect } from "react"
import SEO from "../components/seo"
import Header from "../components/blogHeader"
import Footer from "../components/footer"
import { Link } from "gatsby"

// Styles
import styles from "../styles/pages/success.module.scss"

const ReservationSuccess = () => {
  useEffect(() => {
    localStorage.removeItem("name")
    localStorage.removeItem("timeSlot")
    localStorage.removeItem("date")
    localStorage.removeItem("reservationId")
  }, [])
  return (
    <>
      <Header />
      <SEO title="Reservation Cancelled" />
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.info}>
            <h1>Successfully Cancelled</h1>

            <p className={styles.msg}>
              Thank you, your reservation has been cancelled.
            </p>
          </div>

          <div className={styles.btnGroup}>
            <Link to="/reservations">
              <button className={styles.btn2}>Make Another Reservation</button>
            </Link>

            <Link to="/">
              <button className={styles.btn}>Back To Home</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ReservationSuccess
